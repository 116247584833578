<template>
    <el-select
        :class="config.class"
        v-model="value"
        clearable
        :placeholder="config.placeholder"
        @change="onChange"
        @clear="onClear"
    >
        <el-option
            v-for="(item, i) in config.options"
            :key="i"
            :label="item.label"
            :value="{
                value: item.value,
                fieldName: config.fieldName,
                type: item.type,
            }"
        >
        </el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        filter: undefined,
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            valueBeforeClear: undefined,
            value: undefined,
        };
    },
    created() {
        const FIELD_NAME = this.config.fieldName;
        if (this.filter.fieldName !== FIELD_NAME) return;
        const TYPE = this.filter.type;
        const OPTIONS = this.config.options;
        if (TYPE === 'normal') {
            OPTIONS.forEach((option) => {
                if (option.value === this.filter.value) {
                    const DATA = {
                        fieldName: FIELD_NAME,
                        type: TYPE,
                        value: option.value,
                    };
                    this.value = DATA;
                    this.onChange(DATA);
                }
            });
        } else if (TYPE === 'operator') {
            OPTIONS.forEach((option) => {
                const VLAUE = this.filter.value;
                const COLUMN = this.filter.column;
                if (
                    option.value.value === VLAUE
                    && option.value.column === COLUMN
                ) {
                    const DATA = {
                        fieldName: FIELD_NAME,
                        type: TYPE,
                        value: option.value,
                    };
                    this.value = DATA;
                    this.onChange(DATA);
                }
            });
        }
    },
    methods: {
        onChange(value) {
            const isDifferentType = value
                && this.valueBeforeClear
                && value.type !== this.valueBeforeClear.type;
            if (isDifferentType) {
                this.onClear();
            }
            if (value) this.valueBeforeClear = value;
            if (value.type === 'normal') {
                const DATA = {
                    normal: {
                        [this.value.fieldName]: this.value.value,
                    },
                };
                this.$emit('change', DATA);
            } else if (value.type === 'operator') {
                const DATA = {
                    operator: {
                        fieldName: this.value.fieldName,
                        column: this.value.value.column,
                        value: this.value.value.value,
                    },
                };
                this.$emit('change', DATA);
            }
        },
        onClear() {
            let DATA;
            if (this.valueBeforeClear.type === 'normal') {
                DATA = {
                    normal: {
                        [this.valueBeforeClear.fieldName]: undefined,
                    },
                };
            } else if (this.valueBeforeClear.type === 'operator') {
                DATA = {
                    operator: {
                        fieldName: this.valueBeforeClear.fieldName,
                        value: undefined,
                    },
                };
            }
            this.$emit('clear', DATA);
            this.valueBeforeClear = undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox-select {
    margin-bottom: 30px !important;
}
</style>
