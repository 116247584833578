<template>
    <div class="sales-case-data-table flex flex-col">
        <div
            v-for="(item, index) in data"
            :key="index"
            class="search-result-item flex gap-2"
            @click="onGoToCase(item.id)"
        >
            <div class="avatar flex-shrink-0">
                <img :src="item.doctorAvatar || require('@/assets/logo.png')" width="100%" class="object-cover"/>
            </div>
            <div class="flex flex-col flex-1">
                <div class="top-line flex flex-wrap gap-2">
                    <template v-if="item.decoratorTags">
                        <span
                            v-for="tag in item.decoratorTags"
                            :key="tag"
                            class="tag"
                            :class="decoratorTagClass(tag)"
                            >{{ tag }}</span
                        >
                    </template>
                    <span class="date">{{ formatDate(item.data.updatedAt) }}</span>
                    <span class="category" v-if="item.data.data.category">{{ item.data.data.category }}</span>
                </div>
                <div class="title">{{ getDoctorName(item) }}/{{ item.hospital }}/{{ item.veeva }}</div>
                <div class="title">
                    {{ item.title }}
                </div>
            </div>
        </div>
        <infinite-loading
            ref="infinite"
            :identifier="infiniteId"
            @infinite="onUpdate"
        ></infinite-loading>
        <slot name="footer" />
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';

export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        onUpdate: {
            type: Function,
            default: () => {}
        },
        caseRouteName: {
            type: String,
            default: 'Sales_Case'
        }
    },
    data() {
        return {
            multipleSelection: [],
            isIndeterminate: false,
            checkAll: false
        };
    },
    computed: {
        ...mapState({
            infiniteId: state => state.display.infiniteId
        }),
        decoratorTagClass() {
            return tag =>
                ({
                    NEW: 'tag-new',
                    HOT: 'tag-hot'
                }[tag]);
        }
    },
    methods: {
        ...mapActions({
            apiProxy: 'api/apiProxy'
        }),
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        getDoctorName(item) {
            const displayDoctorName = _.get(item, 'data.data.displayDoctorName', '');
            return displayDoctorName === 'hidden' ? '匿名' : item.doctorName;
        },
        async onGoToCase(id) {
            const PAYLOAD = {
                category: 'Case',
                function: 'addViewCount',
                data: id
            };
            this.$emit('go-to-case', id);
            this.apiProxy(PAYLOAD);
            this.$router.push({ name: this.caseRouteName, params: { id } });
        }
    }
};
</script>

<style lang="scss" scoped>
.sales-case-data-table {
    position: relative;
    overflow: auto;
    flex: 1;
    padding: 5px;
    .search-result-item {
        cursor: pointer;
        transition: 0.3s;
        display: grid;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        // align-items: center;
        text-align: center;
        padding: 10px 15px;
        margin-bottom: 15px;
        > * {
            text-align: left;
        }
        .avatar {
            width: 78px;
            height: 78px;
            img {
                border-radius: 50%;
                height: 100%;
            }
        }
        .top-line {
            .date {
                color: #9d9d9d;
                font-size: 16px;
            }
            .tag,
            .category {
                padding: 0 25px;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: black;
                background-color: #e7e7e7;
                border-radius: 4px;
            }
            .tag {
                padding: 0 12px;
                color: white;
                &.tag-new {
                    background-color: #1fb3e1;
                }
                &.tag-hot {
                    background-color: #e11f1f;
                }
            }
        }

        .title {
            word-break: break-all;
        }
    }
}
@media (max-width: 767px) {
  .search-result-item .title {
    font-size: 16px;
  }
}
</style>
