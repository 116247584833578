<template>
    <el-checkbox-group v-model="data" class="checkbox-group" @change="onChange">
        <el-checkbox
            v-for="(item, i) in config.options"
            :key="i"
            :label="item.value"
        >
            {{ item.label }}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>
export default {
    props: {
        filter: undefined,
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            data: [],
        };
    },
    created() {
        this.config.options.forEach((option) => {
            if (
                this.config.fieldName === this.filter.fieldName
                && option.value === this.filter.value
            ) {
                this.data.push(option.value);
                this.onChange([option.value]);
            }
        });
    },
    methods: {
        onChange(value) {
            const DATA = {
                multi: [
                    {
                        column: this.config.fieldName,
                        value,
                    },
                ],
            };
            this.$emit('change', DATA);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox-group {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    margin: -10px 0 0 -30px;
    > * {
        margin: 10px 0 0 30px;
    }
    /deep/ .el-checkbox__label {
        font-size: 15px;
        // margin-bottom: 20px;
    }
}
</style>
