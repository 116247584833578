<template>
    <div class="filter-wrapper">
        <div v-for="(config, i) in rows" :key="i" class="grid sm:grid-cols-3 lg:grid-cols-5 sm:gap-5 gap-2">
            <component
                v-for="(item, j) in config"
                :key="j"
                :filter="filter"
                :is="item.component"
                :config="item.config"
                @change="$emit('change', $event)"
                @clear="$emit('clear', $event)"
            ></component>
        </div>
    </div>
</template>

<script>
import FilterCheckBox from '@/components/CaseFilter/checkbox.vue';
import FilterDropDown from '@/components/CaseFilter/dropdown.vue';

export default {
    props: {
        filter: undefined,
        rows: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        FilterCheckBox,
        FilterDropDown,
    },
};
</script>

<style lang="scss" scoped>
.filter-wrapper {
    > * {
        margin-bottom: 20px;
    }
    :last-child {
        margin-bottom: 0;
    }
    .row {
        display: flex;
        > * {
            margin-right: 10px;
        }
        :last-child {
            margin-right: 0;
        }
    }
}
</style>
